





































import { AddSuite5CodeInput, PropertySubdivisionVisualizationHint, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import addSuiteFiveCodeMutation from './add-suite-five-code.gql';
import {
  AppSuiteFiveAddCodeMutation,
  AppSuiteFiveAddCodeMutationVariables,
} from './__generated__/AppSuiteFiveAddCodeMutation';
import { AppSuiteFiveAddCodeTreeNodeFragment } from './__generated__/AppSuiteFiveAddCodeTreeNodeFragment';

@Component
export default class AddCodeWizardControl extends Vue {
  @StringProp(true)
  readonly customerId!: string;

  public async addCode(input: AddSuite5CodeInput): Promise<void> {
    const { data } = await this.$apollo.mutate<AppSuiteFiveAddCodeMutation, AppSuiteFiveAddCodeMutationVariables>({
      mutation: addSuiteFiveCodeMutation,
      variables: { input },
    });
    if (!data) {
      throw new Error('Suite5 Code nicht gespeichert werden.');
    }
    this.$emit('add-code');
  }

  private pluckId<T>(object: { id: T } | null): T | null {
    return object?.id ?? null;
  }

  private get types(): TreeNodeType[] {
    return [
      TreeNodeType.RootDirectory,
      TreeNodeType.Directory,
      TreeNodeType.Property,
      TreeNodeType.PropertyGroup,
      TreeNodeType.PropertySubdivision,
    ];
  }

  private get selectableTypes(): TreeNodeType[] {
    return [TreeNodeType.PropertySubdivision];
  }

  private get filter(): (T: AppSuiteFiveAddCodeTreeNodeFragment) => boolean {
    return (treeNode) => {
      if (treeNode.__typename !== TreeNodeType.PropertySubdivision) {
        return true;
      }

      return treeNode.visualizationHint !== PropertySubdivisionVisualizationHint.STAIRWELL;
    };
  }
}
