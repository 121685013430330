













import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppSuiteFiveCodesViewQuery,
  AppSuiteFiveCodesViewQueryVariables,
} from './__generated__/AppSuiteFiveCodesViewQuery';
import query from './view.gql';
import { Column } from '@/features/ui/table/model';
import removeSuiteFiveCodeMutation from './remove-suite-five-code.gql';
import {
  AppSuiteFiveRemoveCodeMutation,
  AppSuiteFiveRemoveCodeMutationVariables,
} from './__generated__/AppSuiteFiveRemoveCodeMutation';
import { RemoveSuite5CodeInput } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';

@Component({
  apollo: {
    suite5Codes: {
      query,
      fetchPolicy: 'no-cache',
      variables(this: CodeTable): AppSuiteFiveCodesViewQueryVariables {
        return { skip: this.skip, take: this.take, customerIds: [this.customerId] };
      },
    },
  },
  data() {
    return { suite5Codes: undefined };
  },
})
export default class CodeTable extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  readonly customerId!: string;

  private readonly suite5Codes?: AppSuiteFiveCodesViewQuery['suite5Codes'];

  private readonly columns: Column[] = [
    { name: 'treeNode.name', label: 'Wohnung' },
    { name: 'code', label: 'Code' },
    { name: 'action', label: '', align: 'right' },
  ];

  public refetch(): void {
    this.$apollo.queries.suite5Codes.refetch();
  }

  public async onRemove(suite5Code: AppSuiteFiveCodesViewQuery['suite5Codes']['items'][number]): Promise<void> {
    const input: RemoveSuite5CodeInput = { id: suite5Code.id };
    const { data } = await this.$apollo.mutate<AppSuiteFiveRemoveCodeMutation, AppSuiteFiveRemoveCodeMutationVariables>(
      {
        mutation: removeSuiteFiveCodeMutation,
        variables: {
          input,
        },
      },
    );

    if (!data) {
      throw new Error('Suite5 Code nicht gespeichert werden.');
    }
    this.refetch();
  }

  protected get count(): number {
    return this.suite5Codes?.count ?? 0;
  }
}
