












import { Component, Vue } from 'vue-property-decorator';
import CodeTable from '../../components/suite-five-codes-table/CodeTable.global.vue';

@Component
export default class AppView extends Vue {
  private customerId: string | null = null;

  public $refs!: { table: CodeTable };

  private handleUpdateCustomer(customerId: string | null): void {
    this.customerId = customerId;
  }
}
